

@keyframes dotAnimation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}